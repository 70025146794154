import Api from "./Api";

const API_LOGIN = '/api/v1';
const API_RXCLUB = '/api/v1/rxclub';

const MAX_LIMIT = 50;

// login
export const login = (params = {}) => {
  return Api.post(`${API_LOGIN}/auth/login`, params);
};

// rxclub
export const getDeliveryCoords = (params = {}) => {
  return Api.get(`${API_RXCLUB}/delivery/coords`, params);
};