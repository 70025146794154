import React, { Component } from 'react';
import GoogleMap from 'google-map-react';
import axios from 'axios';
import Pusher from 'pusher-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from "./services/Api";
import { login, getDeliveryCoords } from "./services/ApiService";

const mapStyle = [
  {
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#f5f5f5" }],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#dadada" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [{ color: "#e5e5e5" }],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [{ color: "#eeeeee" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#c9c9c9" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
];

const mapStyles = {
  width: '100%',
  height: '100%',
}

const markerStyle = {
  height: '72px',
  width: '72px',
  marginTop: "-50px"
}

const imgStyleRight = {
  height: '100%'
}

const imgStyleLeft = {
  height: '100%',
  transform: 'scaleX(-1)'
}

const Marker = ({ title, isRight }) => (
  <div style={markerStyle}>
    {/* <img style={isRight ? imgStyleRight : imgStyleLeft} alt={title} src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1662434985965.png" /> */}
    <img style={imgStyleRight} alt={title} src={isRight ? "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1662434985965.png" : "https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1662442492913.png"} />
    {/* <h3>{title}</h3> */}
  </div>
);

const path = [
  {"lat":14.59786,"lng":120.98412},
  {"lat":14.597990000000001,"lng":120.98415000000001},
  {"lat":14.597990000000001,"lng":120.98415000000001},
  {"lat":14.598090000000001,"lng":120.98414000000001},
  {"lat":14.59814,"lng":120.98412},
  {"lat":14.598210000000002,"lng":120.98410000000001},
  {"lat":14.598210000000002,"lng":120.98410000000001},
  {"lat":14.598640000000001,"lng":120.98418000000001},
  {"lat":14.598860000000002,"lng":120.98424000000001},
  {"lat":14.59946,"lng":120.98436000000001},
  {"lat":14.59964,"lng":120.98439},
  {"lat":14.599820000000001,"lng":120.98443},
  {"lat":14.599990000000002,"lng":120.98446000000001},
  {"lat":14.60017,"lng":120.98450000000001},
  {"lat":14.60035,"lng":120.98453},
  {"lat":14.6004,"lng":120.98454000000001},
  {"lat":14.600500000000002,"lng":120.98456000000002},
  {"lat":14.600640000000002,"lng":120.98458000000001},
  {"lat":14.600750000000001,"lng":120.98461},
  {"lat":14.601110000000002,"lng":120.98469000000001},
  {"lat":14.601590000000002,"lng":120.98481000000001},
  {"lat":14.60176,"lng":120.98485000000001},
  {"lat":14.60176,"lng":120.98485000000001},
  {"lat":14.602110000000001,"lng":120.98490000000001},
  {"lat":14.602920000000001,"lng":120.98507000000001},
  {"lat":14.60298,"lng":120.98507000000001},
  {"lat":14.603040000000002,"lng":120.98508000000001},
  {"lat":14.603130000000002,"lng":120.98510000000002},
  {"lat":14.603240000000001,"lng":120.98512000000001},
  {"lat":14.603850000000001,"lng":120.98524},
  {"lat":14.604550000000001,"lng":120.98539000000001},
  {"lat":14.604730000000002,"lng":120.98542},
  {"lat":14.60513,"lng":120.98550000000002},
  {"lat":14.60566,"lng":120.98561000000001},
  {"lat":14.606100000000001,"lng":120.98570000000001},
  {"lat":14.606200000000001,"lng":120.98574},
  {"lat":14.606370000000002,"lng":120.98577000000002},
  {"lat":14.606760000000001,"lng":120.98585000000001},
  {"lat":14.606980000000002,"lng":120.98591},
  {"lat":14.60715,"lng":120.98594000000001},
  {"lat":14.607550000000002,"lng":120.986},
  {"lat":14.607600000000001,"lng":120.98601000000001},
  {"lat":14.6081,"lng":120.98608000000002},
  {"lat":14.608550000000001,"lng":120.98611000000001},
  {"lat":14.608730000000001,"lng":120.98612000000001},
  {"lat":14.608910000000002,"lng":120.98614},
  {"lat":14.609060000000001,"lng":120.98615000000001},
  {"lat":14.60955,"lng":120.98619000000001},
  {"lat":14.60978,"lng":120.98621000000001},
  {"lat":14.610050000000001,"lng":120.98622},
  {"lat":14.61053,"lng":120.98626000000002},
  {"lat":14.61076,"lng":120.98627},
  {"lat":14.610880000000002,"lng":120.98628000000001},
  {"lat":14.611070000000002,"lng":120.98630000000001},
  {"lat":14.611500000000001,"lng":120.98633000000001},
  {"lat":14.611600000000001,"lng":120.98634000000001},
  {"lat":14.611600000000001,"lng":120.98634000000001},
  {"lat":14.611920000000001,"lng":120.98635000000002},
  {"lat":14.611920000000001,"lng":120.98635000000002},
  {"lat":14.611870000000001,"lng":120.98625000000001},
  {"lat":14.61189,"lng":120.98590000000002},
  {"lat":14.61189,"lng":120.98561000000001},
  {"lat":14.611920000000001,"lng":120.98517000000001},
  {"lat":14.611950000000002,"lng":120.98477000000001},
  {"lat":14.61198,"lng":120.98438000000002},
  {"lat":14.612010000000001,"lng":120.98388000000001},
  {"lat":14.612070000000001,"lng":120.9834},
  {"lat":14.612070000000001,"lng":120.9834},
  {"lat":14.613100000000001,"lng":120.98348000000001},
  {"lat":14.61434,"lng":120.98359},
  {"lat":14.615580000000001,"lng":120.98368},
  {"lat":14.616950000000001,"lng":120.98377},
  {"lat":14.616950000000001,"lng":120.98377},
  {"lat":14.616900000000001,"lng":120.98328000000001},
  {"lat":14.616890000000001,"lng":120.98315000000001},
  {"lat":14.61687,"lng":120.98297000000001},
  {"lat":14.616850000000001,"lng":120.98281000000001},
  {"lat":14.616840000000002,"lng":120.98269},
  {"lat":14.616790000000002,"lng":120.98226000000001},
  {"lat":14.616790000000002,"lng":120.98218000000001},
  {"lat":14.616740000000002,"lng":120.9818},
  {"lat":14.616690000000002,"lng":120.98135},
  {"lat":14.616690000000002,"lng":120.98127000000001},
  {"lat":14.616670000000001,"lng":120.98113000000001},
  {"lat":14.616660000000001,"lng":120.98103},
  {"lat":14.616650000000002,"lng":120.98089000000002},
  {"lat":14.616640000000002,"lng":120.98061000000001},
  {"lat":14.61663,"lng":120.98042000000001},
  {"lat":14.616600000000002,"lng":120.97994000000001},
  {"lat":14.61658,"lng":120.97964},
  {"lat":14.616570000000001,"lng":120.97932000000002},
  {"lat":14.616570000000001,"lng":120.97914000000002},
  {"lat":14.616570000000001,"lng":120.97908000000001},
  {"lat":14.61658,"lng":120.97904000000001},
  {"lat":14.61659,"lng":120.97897},
  {"lat":14.616610000000001,"lng":120.97885000000001},
  {"lat":14.616620000000001,"lng":120.97876000000001},
  {"lat":14.616650000000002,"lng":120.97856000000002},
  {"lat":14.616670000000001,"lng":120.97837000000001},
  {"lat":14.61668,"lng":120.97817},
  {"lat":14.616690000000002,"lng":120.97813000000001},
  {"lat":14.61672,"lng":120.97775000000001},
  {"lat":14.616740000000002,"lng":120.97754},
  {"lat":14.616750000000001,"lng":120.97741},
  {"lat":14.61677,"lng":120.97727},
  {"lat":14.616790000000002,"lng":120.97710000000001},
  {"lat":14.616840000000002,"lng":120.97657000000001},
  {"lat":14.616880000000002,"lng":120.97634000000001}
];

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // center: { lat: 14.6020881836, lng: 120.983965267 },
      // origin: { lat: 14.597852, lng: 120.984157 },
      // destination: { lat: 14.6167408664, lng: 120.976317761 },
      // locations: {
      //   "rider": {
      //     lat: 14.6020881836, lng: 120.983965267
      //   }
      // },
      users_online: {},
      current_user: 'rider',
      zoom: 15,
      timeElapsed: 0
    }
  }

  goLogin() {
    login({
      oldId: process.env.REACT_APP_OLDID,
      apiKey: process.env.REACT_APP_APIKEY
    })
    .then(response => {
      const token = JSON.stringify(response.headers.authorization);
      // console.log(`token=${token}`)
      const user = token && JSON.parse(token);
      Api.setAuthHeader(user);
      const deliveryId = this.getQueryVariable('deliveryId');
      this.getCoords(deliveryId);
    })
    .catch(e => {
      console.warn('get login ERROR', e.message);
    })
  }

  latRad(lat) {
    var sin = Math.sin(lat * Math.PI / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }
  
  getZoom(lat_a, lng_a, lat_b, lng_b) {
  
        let latDif = Math.abs(this.latRad(lat_a) - this.latRad(lat_b))
        let lngDif = Math.abs(lng_a - lng_b)
  
        let latFrac = latDif / Math.PI 
        let lngFrac = lngDif / 360 
  
        let lngZoom = Math.log(1/latFrac) / Math.log(2)
        let latZoom = Math.log(1/lngFrac) / Math.log(2)
        let zoom = Math.min(lngZoom, latZoom)
        return zoom;
  
  }

  getCoords(deliveryId) {
    getDeliveryCoords({deliveryId})
    .then(({data}) => {
      // if (data.data.rider_latitude_prev) {
      //   this.setState({
      //     origin: { lat: data.data.rider_latitude, lng: data.data.rider_longitude },
      //     prev_origin: { lat: data.data.rider_latitude_prev, lng: data.data.rider_longitude_prev },
      //     destination: { lat: data.data.customer_latitude, lng: data.data.customer_longitude },
      //     center: { lat: (data.data.rider_latitude + data.data.rider_latitude_prev) / 2, lng: (data.data.rider_longitude + data.data.rider_longitude_prev) / 2 },
      //     locations: {
      //       "rider": {
      //         lat: data.data.rider_latitude, lng: data.data.rider_longitude
      //       }
      //     },
      //     zoom: this.getZoom(data.data.rider_latitude_prev, data.data.rider_longitude_prev, data.data.rider_latitude, data.data.rider_longitude)
      //   });
      // }
      // else {
        this.setState({
          origin: { lat: data.data.rider_latitude, lng: data.data.rider_longitude },
          prev_origin: { lat: data.data.rider_latitude_prev, lng: data.data.rider_longitude_prev },
          destination: { lat: data.data.customer_latitude, lng: data.data.customer_longitude },
          center: { lat: (data.data.rider_latitude + data.data.customer_latitude) / 2, lng: (data.data.rider_longitude + data.data.customer_longitude) / 2 },
          locations: {
            "rider": {
              lat: data.data.rider_latitude, lng: data.data.rider_longitude
            }
          },
          zoom: this.getZoom(data.data.rider_latitude, data.data.rider_longitude, data.data.customer_latitude, data.data.customer_longitude)
        });
      // }
    })
    .catch(e => {
      console.warn('get coords ERROR', e.message);
    })
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    // console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] == variable){return pair[1];}
    }
    return(false);
  }

  componentDidMount() {
    this.goLogin();
    const deliveryId = this.getQueryVariable('deliveryId');
    // console.log(`deliveryId=${this.getQueryVariable('deliveryId')}`)
    this.timer = setInterval(() => {
      this.getCoords(deliveryId);
    }, 5000);    

    // this.getLocation();
    let pusher = new Pusher('PUSHER_APP_KEY', {
      authEndpoint: "http://localhost:3128/pusher/auth",
      cluster: "mt1"
    });

    this.presenceChannel = pusher.subscribe('presence-channel');
    this.presenceChannel.bind('pusher:subscription_succeeded', members => {
      this.setState({
        users_online: members.members,
        current_user: members.myID
      });
      // this.getLocation();
      this.notify();
    });

    this.presenceChannel.bind('location-update', body => {
      this.setState((prevState, props) => {
        const newState = { ...prevState };
        newState.locations[`${body.username}`] = body.location;
        return newState;
      });
    });

    this.presenceChannel.bind('pusher:member_removed', member => {
      this.setState((prevState, props) => {
        const newState = { ...prevState };
        // remove member location once they go offline
        delete newState.locations[`${member.id}`];
        // delete member from the list of online users
        delete newState.users_online[`${member.id}`];

        return newState;
      })
      this.notify();
    })

    this.presenceChannel.bind('pusher:member_added', member => {
      this.notify();
    })
  }

  getLocation = () => {
    if ("geolocation" in navigator) {
      // get the longitude & latitude then update the map center as the new user location
      navigator.geolocation.watchPosition(position => {
        let location = { lat: position.coords.latitude, lng: position.coords.longitude };

        this.setState((prevState, props) => {
          let newState = { ...prevState };

          newState.center = location;
          newState.locations[`${prevState.current_user}`] = location;
          newState.zoom = 10;

          return newState;
        });

        // axios.post("http://localhost:3128/update-location", {
        //   username: this.state.current_user,
        //   location: location
        // }).then(res => {
        //   if (res.status === 200) {
        //     console.log("new location updated successfully");
        //   }
        // });
      })
    } else {
      alert("Sorry, geolocation is not available on your device. You need that to use this app");
    }
  }

  notify = () => toast(`Users online : ${Object.keys(this.state.users_online).length}`, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    type: 'info'
  });

  render() {
    const apiIsLoaded = (map, maps) => {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(map);
      const origin = this.state.origin;
      const destination = this.state.destination; 
  
      directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
          // console.log(`${JSON.stringify(result.routes[0].legs[0].steps.map(item => item.lat_lngs))}`)
        }
      );
      map.setOptions({
        styles: mapStyle
      })
    };

    return (
      <div>
        { typeof this.state.center !== 'undefined' &&
          <GoogleMap
            // style={mapStyles}
            style={mapStyles}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_APIKEY}}
            center={this.state.center}
            zoom={this.state.zoom}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          >
            {Object.keys(this.state.locations).map((username, id) => {
              let isRight = true;
              // if (this.state.timeElapsed > 1) {
              //   isRight = path[this.state.timeElapsed].lng > path[this.state.timeElapsed-2].lng
              // }
              if (typeof this.state.prev_origin && this.state.prev_origin.lng) {
                isRight = this.state.origin.lng > this.state.prev_origin.lng
              }
              else if (typeof this.state.destination && this.state.destination.lng) {
                isRight = this.state.destination.lng > this.state.origin.lng
              }
              return (
                <Marker
                  key={id}
                  title={`${username + "'s location"}`}
                  isRight={isRight}
                  // lat={this.state.locations[`${username}`].lat}
                  // lng={this.state.locations[`${username}`].lng}
                  lat={this.state.origin.lat}
                  lng={this.state.origin.lng}
                >
                </Marker>
              );
            })}
          </GoogleMap>
        }
        <ToastContainer />
      </div>
    )
  }
}

export default App;